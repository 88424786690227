import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { PageHeader, Typography } from 'antd';
import FlextimeContext from '../../Context/Flextime/FlextimeContext';
import TimeEntryContext from '../../Context/TimeEntry/TimeEntryContext';
import TimeWindowContext from '../../Context/TimeWindow/TimeWindowContext';
import TotalTable from './TotalTable';
import UserContext from '../../Context/User/UserContext';

const { Title } = Typography;

const TotalCard = () => {
  const { isAdmin } = useContext(UserContext);
  const { start, end } = useContext(TimeWindowContext);
  const { dataSource: flextimes } = useContext(FlextimeContext);
  const { dataSource: timeentries } = useContext(TimeEntryContext);
  const [worked, setWorked] = useState(0);
  const [overtime, setOvertime] = useState(0);
  const [sicktime, setSicktime] = useState(0);
  const [leavetime, setLeavetime] = useState(0);
  const [unpaid, setUnpaid] = useState(0);

  useEffect(() => {
    const sumFlextimes = () => {
      let accumulator = 0;
      flextimes.forEach(({ createdAt, updatedAt }) => {
        const timeDiff = moment(updatedAt).diff(moment(createdAt));
        accumulator += moment.duration(timeDiff).asHours();
      });

      return Math.round(accumulator * 100) / 100;
    };

    const sumTimeEntries = (type) => {
      let accumulator = 0;
      timeentries.forEach(({ type: currentType, duration, approved }) => {
        if (approved && currentType === type) {
          accumulator += moment.duration(duration, 'seconds').asHours();
        }
      });

      return Math.round(accumulator * 100) / 100;
    };

    setWorked(sumFlextimes());
    setOvertime(sumTimeEntries('OverTime'));
    setSicktime(sumTimeEntries('SickTime'));
    setLeavetime(sumTimeEntries('LeaveTime'));
    setUnpaid(sumTimeEntries('Unpaid'));
  }, [flextimes, timeentries]);

  if (!isAdmin) {
    return null;
  }

  return (
    <>
      <PageHeader
        title={(
          <Title level={3}>
            {`Totals for: ${start.format('ddd DD')} - ${end.format('ddd DD')}`}
          </Title>
        )}
        bordered={false}
        style={{ width: '100%', overflow: 'auto' }}
        bodyStyle={{ padding: 0 }}
      >
        <TotalTable dataSource={[{
          worked, overtime, sicktime, leavetime, unpaid,
        }]}
        />
      </PageHeader>
    </>
  );
};

export default TotalCard;
