import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import { getItems } from './Response';

export const listEnumValues = `query ListEnumValues($enum: String!) {
  enum: __type(name: $enum) {
    enumValues {
      name
    }
  }
}`;

export const listModels = (
  key,
  selected = null,
  start = null,
  end = null,
  sort = null,
  setDataSource = () => {},
  setLoading = () => {},
  dateField = 'createdAt',
) => {
  setLoading(true);

  const variables = {
    limit: 99999,
  };

  if (selected) {
    variables.filter = {
      owner: { eq: selected },
    };
  }

  if (start && end) {
    variables.filter = {
      ...variables.filter,
      [dateField]: { between: [start.toISOString(), end.toISOString()] },
    };
  }

  API.graphql(graphqlOperation(queries[key], variables)).then((response) => {
    const items = getItems(response, key);
    if (sort) {
      items.sort(sort);
    }
    setDataSource(items);
    setLoading(false);
  });
};

export const Query = {
  listEnumValues,
};

export default Query;
