import React, { useContext, useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import PropTypes from 'prop-types';
import moment from 'moment';
import FlextimeContext from './FlextimeContext';
import { onCreateModel, onUpdateModel } from '../../Helpers/GraphQL/Subscription';
import SidebarContext from '../Sidebar/SidebarContext';
import { deleteRecord } from '../../Helpers/GraphQL/Mutation';
import TimeWindowContext from '../TimeWindow/TimeWindowContext';
import { listModels } from '../../Helpers/GraphQL/Query';

const FlextimeProvider = ({ children }) => {
  const { selected } = useContext(SidebarContext);
  const { start, end } = useContext(TimeWindowContext);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [previousLoad, setPreviousLoad] = useState({});
  const [subscriptions, setSubscriptions] = useState([]);

  const action = async (operation, variables) => {
    setLoading(true);
    await API.graphql(graphqlOperation(operation, variables));
    setLoading(false);
  };

  const deleteModel = (id) => {
    const key = 'deleteFlextime';

    deleteRecord(key, id).then(() => {
      setDataSource((prevDataSource) => prevDataSource.filter((record) => record.id !== id));
    });
  };

  useEffect(() => {
    const { selected: prevSelected, start: prevStart } = previousLoad;

    if (selected !== prevSelected) {
      const params = { owner: selected };

      subscriptions.map((sub) => sub.unsubscribe());

      setSubscriptions([
        onCreateModel('onCreateFlextime', params, setDataSource, setLoading),
        onUpdateModel('onUpdateFlextime', params, setDataSource, setLoading),
      ]);

      setPreviousLoad((prevPreviousLoad) => ({ ...prevPreviousLoad, selected }));
    }

    if (selected !== prevSelected || start !== prevStart) {
      const sort = (a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix();
      listModels('listFlextimes', selected, start, end, sort, setDataSource, setLoading);
      setPreviousLoad((prevPreviousLoad) => ({ ...prevPreviousLoad, start }));
    }
  }, [selected, start, end, previousLoad, subscriptions]);

  return (
    <FlextimeContext.Provider value={{
      dataSource, loading, deleteModel, action,
    }}
    >
      {children}
    </FlextimeContext.Provider>
  );
};

FlextimeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FlextimeProvider;
