export const getCustomFieldValue = (attribute, attributeName) => {
  const { value } = attribute.find((
    { projectCustomField: { field: { name } } },
  ) => name === attributeName);

  return value;
};

export const getRate = (idReadable, project, customPrices) => {
  const customPrice = customPrices.find((item) => idReadable === item.id);
  return customPrice ? customPrice.rate : project.rate;
};

export const getPrice = (idReadable, customFields, project, customPrices = []) => {
  const rate = getRate(idReadable, project, customPrices);

  const spent = getCustomFieldValue(customFields, 'Spent time');
  if (spent) {
    return Math.round(rate * (spent.minutes / 60) * 100) / 100;
  }

  return 0;
};

export const sourceToRow = ({
  commentsCount, comments, customFields, idReadable, links = [], summary,
}, project, customPrices = [], state = []) => {
  const estimation = getCustomFieldValue(customFields, 'Estimation');
  const spent = getCustomFieldValue(customFields, 'Spent time');

  let object = {
    id: idReadable,
    summary,
    commentsCount,
    comments,
    type: getCustomFieldValue(customFields, 'Type').name,
    state: getCustomFieldValue(customFields, 'State').name,
    estimation: {
      presentation: estimation ? estimation.presentation : '',
      minutes: estimation ? estimation.minutes : '',
    },
    spentTime: {
      presentation: spent ? spent.presentation : '',
      minutes: spent ? spent.minutes : '',
    },
    rate: getRate(idReadable, project, customPrices),
    price: getPrice(idReadable, customFields, project, customPrices),
  };

  if (links && links.length) {
    links.forEach(({ direction, issues, linkType: { name } }) => {
      if (name === 'Subtask') {
        if (direction === 'OUTWARD') {
          issues.forEach((issue) => {
            const issueState = getCustomFieldValue(issue.customFields, 'State').name;
            if (state.includes(issueState)) {
              if (!object.children) {
                object.children = [];
              }
              object.children.push(sourceToRow(issue, project, customPrices));
              object.price = object.children.reduce(
                (accumulator, { price }) => accumulator + price,
                0,
              );
            }
          });
        }

        if (direction === 'INWARD') {
          issues.forEach((issue) => {
            object = { parent_id: issue.idReadable, ...object };
          });
        }
      }
    });
  }

  return object;
};

export const findWithNested = (data, row) => {
  let result = false;

  for (let i = 0; i < data.length; i += 1) {
    if (data[i].id === row.id) {
      result = data[i];
      break;
    }

    if (data[i].children && data[i].children.length) {
      const existsOnChildren = findWithNested(data[i].children, row);
      if (existsOnChildren) {
        result = existsOnChildren;
        break;
      }
    }
  }

  return result;
};

export const rowExistsInDataSource = (dataSource, row) => !!dataSource.find((data) => {
  if (data.children && data.children.length) {
    const existsOnChildren = rowExistsInDataSource(data.children, row);
    if (existsOnChildren) {
      return true;
    }
  }

  return data.id === row.id;
});

export const getSelectedRowKeys = (dataSource, selected) => {
  const selection = [];

  selected.forEach((row) => {
    const exists = rowExistsInDataSource(dataSource, row);
    if (exists) {
      selection.push(row.id);
    }
  });

  return selection;
};

const mustFilterSource = (source) => {
  const { customFields, links } = source;
  if (!links) {
    return true;
  }

  const issueType = getCustomFieldValue(customFields, 'Type').name;

  if (issueType === 'Feature' || issueType === 'Epic') {
    if (links && links.length) return !links.find(({ direction, issues }) => direction === 'OUTWARD' && issues.length);
  }

  return false;
};

export const processDataSource = (dataSource, project, customPrices, state) => {
  const processedDataSource = [];

  dataSource.forEach((source) => {
    if (!mustFilterSource(source)) {
      processedDataSource.push(sourceToRow(source, project, customPrices, state));
    }
  });

  return processedDataSource;
};

const Issue = {
  getCustomFieldValue,
  getPrice,
  getSelectedRowKeys,
  sourceToRow,
  findWithNested,
  processDataSource,
};

export default Issue;
